<script context="module">
    import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js';
    import { DRACOLoader } from 'three/examples/jsm/loaders/DRACOLoader.js';

    const dracoLoader = new DRACOLoader();
    dracoLoader.setDecoderPath('/draco/')
    const gltfLoader = new GLTFLoader();
    gltfLoader.setDRACOLoader(dracoLoader);

    function loadGLTFModel(url) {
        return new Promise((resolve) => {
            gltfLoader.load(
                url,
                (gltf) => {
                    resolve(gltf.scene.children[0].geometry);
                }
            );
        });
    };

    async function loadGeometries(list) {
        let result = [];
        for (const el of list) {
            const geometry = await loadGLTFModel(el);
            result.push(geometry);
        } return result;
    };

    export { loadGeometries };
</script>