<div class="wrapper">
    <svg class="icon" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M20.9039 12.2561L17.7551 15.4024C17.6087 15.5488 17.389 15.5488 17.2426 15.4024L12.0069 10.1707L6.77117 15.4024C6.62471 15.5488 6.40503 15.5488 6.25858 15.4024L3.10984 12.2561C2.96339 12.1098 2.96339 11.8902 3.10984 11.7439L8.60183 6.2561L11.7506 3.10976C11.897 2.96341 12.1167 2.96341 12.2632 3.10976L15.4119 6.2561L20.9039 11.7439C21.032 11.872 21.032 12.1098 20.9039 12.2561ZM15.4119 17.2317L12.2632 14.0854C12.1167 13.939 11.897 13.939 11.7506 14.0854L8.60183 17.2317C8.45538 17.378 8.45538 17.5976 8.60183 17.7439L11.7506 20.8902C11.897 21.0366 12.1167 21.0366 12.2632 20.8902L15.4119 17.7439C15.54 17.5976 15.54 17.3598 15.4119 17.2317Z" fill="var(--neutral-900)"/>
    </svg>
    <span>You break it, you buy it, pal.</span>
</div>

<style>
    .wrapper {
        display: none;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 200;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--size-10);
        padding: 10vw;
        background-color: var(--neutral-100);
    }

    .icon {
        width: var(--size-13);
        height: var(--size-13);
    }

    span {
        font-family: Sora;
        font-size: var(--size-9);
        font-weight: 600;
        line-height: 140%;
        text-align: center;
        color: var(--neutral-900);
    }

    @media only screen and (max-width: 279px) {
        .wrapper {
            display: flex;
        }
    }
</style>