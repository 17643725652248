<script type="text/javascript">
    import { page } from '$app/stores';
    import { onMount } from 'svelte';
    import { quintOut } from 'svelte/easing';
    import { fade } from 'svelte/transition';
    import { currentTheme } from '$routes/stores';
    import { get } from 'svelte/store';

    let modalFocus;
    let modalButton;
    let isModalOpen = false;
    let modalElements = [];

    let menuButton;
    let isMenuOpen = false;

    let switchButtons;
    let switchToggled = false;
    let savedTheme = null;

    let targetWork;
    let targetAbout;

    $: targetWork = $page.url.pathname === '/' ? true : false;
    $: targetAbout = $page.url.pathname === '/about' ? true : false;

    function toggleModal() {
        isModalOpen = !isModalOpen;
        const buttonAria = isModalOpen ? 'true' : 'false';
        modalButton.setAttribute('aria-expanded', buttonAria);
        document.body.style.overflow = isModalOpen ? 'hidden' : '';
        trapHandler();
    };

    function trapHandler() {
        if (isModalOpen === true) {
            document.addEventListener('keydown', focusHandler);
            modalFocus = 0;
            modalElements[modalFocus].focus();
        } else {
            document.removeEventListener('keydown', focusHandler);
        }
    }

    function focusHandler(event) {
        if (event.key === 'Tab' && !event.shiftKey) {
            if (modalFocus === 2) {
                modalFocus = 0;
            } else {
                modalFocus += 1;
            }
            modalElements[modalFocus].focus();
            event.preventDefault();
        };
        if (event.key === 'Tab' && event.shiftKey) {
            if (modalFocus === 0) {
                modalFocus = 2;
            } else {
                modalFocus -= 1;
            }
            modalElements[modalFocus].focus();
            event.preventDefault();
        };
    }

    function toggleMenu() {
        isMenuOpen = !isMenuOpen;
        const buttonAria = isMenuOpen ? 'true' : 'false';
        menuButton.setAttribute('aria-expanded', buttonAria);
        document.body.style.overflow = isMenuOpen ? 'hidden' : '';
        document.activeElement.blur();
    };

    function menuClose() {
        if (isMenuOpen === true) {
            toggleMenu()
        }
    }

    function closeBackdrop(event) {
        if ((event.key == "Escape") && isMenuOpen) {
            toggleMenu()
        } else if ((event.key == "Escape") && isModalOpen) {
            toggleModal()
        }
    };

    function toggleSwitch() {
        switchToggled = !switchToggled
        
        if (get(currentTheme) == 'light') {
            currentTheme.set('dark');
        } else {
            currentTheme.set('light');
        }

        if (switchToggled == true) {
            document.documentElement.setAttribute('data-color-theme', 'dark');
            for (let i = 0; i < switchButtons.length; i++) {
                switchButtons[i].setAttribute('aria-checked', 'true');
            }
        }
        else {
            document.documentElement.setAttribute('data-color-theme', 'light');
            for (let i = 0; i < switchButtons.length; i++) {
                switchButtons[i].setAttribute('aria-checked', 'false');
            }
        }
        localStorage.setItem('savedTheme', get(currentTheme));
    };

    function toggleSwitchKeyboard(event) {
        if (event.key === 'Enter') {
            toggleSwitch()
        }
    };

    function themeChecker() {
        savedTheme = localStorage.getItem('savedTheme');

        if (savedTheme === 'dark') {
            toggleSwitch()
        }

        if (savedTheme === null) {
            if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
                toggleSwitch()
            }
        }
    }

    onMount(() => {
        menuButton = document.getElementById('MENU-BUTTON');
        modalButton = document.getElementById('MODAL-CLOSE');
        switchButtons = document.querySelectorAll('.theme-switch');

        modalElements.push(document.getElementById('MODAL-MAIL'));
        modalElements.push(document.getElementById('MODAL-LINK'));
        modalElements.push(document.getElementById('MODAL-CLOSE'));

        themeChecker();
    })
</script>

<svelte:window on:keydown={ closeBackdrop } />

<div class="modal-container">
    <div class="menu-modal"
        class:visible={ isModalOpen }
        role="dialog"
        aria-label="Contact modal"
    >
        <div class="modal-wrapper">
            <div class="modal-text">
                <div class="modal-title">
                    <h2 class="special-heading">Get in touch</h2>
                    <button id="MODAL-CLOSE"
                        class="interactive reversed"
                        aria-label="Close contact dialog"
                        aria-controls="contact-dialog"
                        aria-expanded="false"
                        on:click={ toggleModal }
                    >
                        <svg class="menu-icon" width="100%" height="100%" viewBox="0 0 48 48" fill="currentColor">
                            <rect class="rect top" width="32" height="8" x="8" y="20" rx="2" transform="rotate(45)" fill="currentColor"></rect>
                            <rect class="rect bot" width="32" height="8" x="8" y="20" rx="2" transform="rotate(-45)" fill="currentColor"></rect>
                        </svg>
                    </button>
                </div>
                <p class="body1" style="margin-top: var(--size-5); color: var(--neutral-300)">Drop an email or a message on LinkedIn to get things started</p>
            </div>
            <div class="c2a">
                <a href="mailto:contact@uxdrop.club?subject=Hey,%20Alex"
                    id="MODAL-MAIL"
                    class="interactive reversed"
                    style="height: var(--size-13)"
                    aria-label="Send an email to Alex"
                ><span class="typography">send an email</span></a>
                <span class="interim">or</span>
                <a href="https://www.linkedin.com/in/alex-i-brad/"
                    target="_blank"
                    id="MODAL-LINK"
                    class="interactive reversed"
                    aria-label="Open Alex's Linkedin profile in a new tab"
                >
                    <svg class="menu-icon" width="100%" height="100%" viewBox="0 0 48 48" fill="currentColor">
                        <path d="M14.0572 9.42862C14.0572 12 12 14.0572 9.42862 14.0572C6.85719 14.0572 4.80005 12 4.80005 9.42862C4.80005 6.85719 6.85719 4.80005 9.42862 4.80005C12 4.80005 14.0572 6.85719 14.0572 9.42862Z"/>
                        <path d="M43.2 28.9715V43.2H34.8V31.2C34.8 23.3143 26.4 24 26.4 31.2V43.2H17.8286V17.8286H26.2286V22.4572C30 15.7715 43.2 15.2572 43.2 28.9715Z"/>
                        <path d="M13.5429 17.8286H5.14291V43.2H13.5429V17.8286Z"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</div>
{#if isModalOpen}
    <div id="MODAL-BACKDROP"
        class="backdrop"
        role="presentation"
        on:click={ toggleModal }
        transition:fade={{ duration: 500, easing: quintOut}}
    ></div>
{/if}

<div class="nav-container">
    <nav id="NAV" class:dropdown={ isMenuOpen }>
        <div role="list" class="menu-container">
            <div class="menu-top">
                <div role="listitem">
                    <a href="/"
                        class="logo interactive"
                        aria-label="Alex Brad homepage"
                    >
                        <svg xmlns="http://www.w3.org/2000/svg" class="symbol" height="100%" viewBox="0 0 48 48" fill="none">
                            <g clip-path="url(#clip0_1406_7744)">
                                <path d="M47.7437 24.6829L39.3471 33.0732C38.9565 33.4634 38.3707 33.4634 37.9802 33.0732L24.0183 19.122L10.0564 33.0732C9.6659 33.4634 9.08009 33.4634 8.68955 33.0732L0.292906 24.6829C-0.0976354 24.2927 -0.0976354 23.7073 0.292906 23.3171L14.9382 8.68293L23.3349 0.292683C23.7254 -0.097561 24.3112 -0.097561 24.7018 0.292683L33.0984 8.68293L47.7437 23.3171C48.0854 23.6585 48.0854 24.2927 47.7437 24.6829ZM33.0984 37.9512L24.7018 29.561C24.3112 29.1707 23.7254 29.1707 23.3349 29.561L14.9382 37.9512C14.5477 38.3415 14.5477 38.9268 14.9382 39.3171L23.3349 47.7073C23.7254 48.0976 24.3112 48.0976 24.7018 47.7073L33.0984 39.3171C33.4401 38.9268 33.4401 38.2927 33.0984 37.9512Z" fill="currentColor"/>
                            </g>
                        </svg>
                        <span class="typography">Alex Brad</span>
                    </a>
                </div>
                <div class="menu-desktop">
                    <div role="listitem">
                        <a href="/" 
                            class="interactive"
                            class:active={ targetWork }
                            style="margin-right: var(--size-6)"
                            aria-label="Work / Homepage"
                        ><span class="typography">Work</span></a>
                    </div>
                    <div role="listitem">
                        <a href="/about"
                            class="interactive"
                            class:active={ targetAbout }
                            style="margin-right: var(--size-6)"
                            aria-label="About me"
                        ><span class="typography">About</span></a>
                    </div>
                    <div role="listitem">
                        <button class="interactive"
                            aria-label="Open contact dialogue"
                            on:click={ toggleModal }
                        ><span class="typography">Contact</span></button>
                    </div>
                </div>
                <div role="listitem" class="menu-mobile">
                    <button id="MENU-BUTTON"
                        class="interactive"
                        aria-label="Toggle navigation menu"
                        aria-controls="primary-navigation"
                        aria-expanded="false"
                        on:click={ toggleMenu }
                    >
                        <svg class="menu-icon" width="100%" height="100%" viewBox="0 0 48 48" fill="currentColor">
                            <rect class="rect top" width="32" height="8" x="8" y="20" rx="2" transform="rotate(45)" fill="currentColor"></rect>
                            <rect class="rect bot" width="32" height="8" x="8" y="20" rx="2" transform="rotate(-45)" fill="currentColor"></rect>
                        </svg>
                    </button>
                </div>
            </div>
            <div class="menu-bot">
                <div class="menu-splitter">
                    <div class="first-area">
                        <div role="listitem">
                            <a href="/"
                                class="interactive"
                                class:active={ targetWork }
                                aria-label="Work / Homepage"
                                tabindex="-1"
                                on:click={ menuClose }
                            ><span class="typography">Work</span></a>
                        </div>
                        <div role="listitem">
                            <a href="/about"
                                class="interactive"
                                class:active={ targetAbout }
                                aria-label="About me"
                                tabindex="-1"
                                on:click={ menuClose }
                            ><span class="typography">About</span></a>
                        </div>
                    </div>
                    <div class="divider"></div>
                    <div class="second-area">
                        <div class="text">
                            <h2 class="special-heading">Get in touch</h2>
                            <p class="body1" style="margin-top: var(--size-5); color: var(--neutral-800)">Drop an email or a message on LinkedIn to get things started</p>
                        </div>
                        <div role="listitem" class="c2a">
                            <a href="mailto:contact@uxdrop.club?subject=Hey,%20Alex"
                                class="interactive"
                                style="height: var(--size-13)"
                                aria-label="Send an email to Alex"
                                tabindex="-1"
                            ><span class="typography">send an email</span></a>
                            <span class="interim">or</span>
                            <a href="https://www.linkedin.com/in/alex-i-brad/"
                                target="_blank"
                                class="interactive"
                                aria-label="Open Alex's Linkedin profile in a new tab"
                                tabindex="-1"
                            >
                                <svg class="linkedin-icon" viewBox="0 0 48 48" fill="currentColor">
                                    <path d="M14.0572 9.42862C14.0572 12 12 14.0572 9.42862 14.0572C6.85719 14.0572 4.80005 12 4.80005 9.42862C4.80005 6.85719 6.85719 4.80005 9.42862 4.80005C12 4.80005 14.0572 6.85719 14.0572 9.42862Z"/>
                                    <path d="M43.2 28.9715V43.2H34.8V31.2C34.8 23.3143 26.4 24 26.4 31.2V43.2H17.8286V17.8286H26.2286V22.4572C30 15.7715 43.2 15.2572 43.2 28.9715Z"/>
                                    <path d="M13.5429 17.8286H5.14291V43.2H13.5429V17.8286Z"/>
                                </svg>
                            </a>
                        </div>
                    </div>
                </div>
                <div class="switch-container">
                    <div class="theme-switch switch-wrapper"
                        role="checkbox"
                        aria-checked="false"
                        aria-label="Contact modal"
                        tabindex="-1"
                        on:click={ toggleSwitch }
                        on:keydown={ toggleSwitchKeyboard }
                    >
                        <svg class="switch-icon light" class:toggled={ !switchToggled } width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2213 6.72105C21.2334 7.64302 21.7805 9.09552 21.4659 10.5936C20.5615 14.8997 21.7823 19.549 25.1166 22.8834C28.4509 26.2177 33.1003 27.4385 37.4064 26.5341C38.9045 26.2195 40.357 26.7666 41.2789 27.7787C42.2447 28.8389 42.6981 30.5273 41.9029 32.1423C40.9837 34.0093 39.7486 35.7567 38.2019 37.3034C30.6065 44.8988 18.2919 44.8988 10.6965 37.3034C3.10115 29.7081 3.10115 17.3935 10.6965 9.79809C12.2432 8.25139 13.9907 7.01629 15.8576 6.09709C17.4727 5.30192 19.1611 5.75526 20.2213 6.72105ZM15.1825 13.8034C15.1007 13.8812 15.0196 13.9604 14.9392 14.0407C9.68694 19.293 9.68694 27.8086 14.9392 33.0608C20.1914 38.313 28.707 38.313 33.9593 33.0608C34.0396 32.9804 34.1187 32.8993 34.1966 32.8175C29.3668 32.711 24.5635 30.8156 20.874 27.126C17.1844 23.4364 15.289 18.6332 15.1825 13.8034Z"/>
                            <path d="M30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11V12H37C38.6569 12 40 13.3431 40 15C40 16.6569 38.6569 18 37 18H36V19C36 20.6569 34.6569 22 33 22C31.3431 22 30 20.6569 30 19V18H29C27.3431 18 26 16.6569 26 15C26 13.3431 27.3431 12 29 12H30V11Z"/>
                        </svg>
                        <svg class="switch-icon dark" class:toggled={ switchToggled } width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path d="M21 5C21 3.34315 22.3431 2 24 2C25.6569 2 27 3.34315 27 5V9C27 10.6569 25.6569 12 24 12C22.3431 12 21 10.6569 21 9V5Z"/>
                            <path fill-rule="evenodd" clip-rule="evenodd" d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34ZM24 28C26.2091 28 28 26.2091 28 24C28 21.7909 26.2091 20 24 20C21.7909 20 20 21.7909 20 24C20 26.2091 21.7909 28 24 28Z"/>
                            <path d="M24 36C22.3431 36 21 37.3431 21 39V43C21 44.6569 22.3431 46 24 46C25.6569 46 27 44.6569 27 43V39C27 37.3431 25.6569 36 24 36Z"/>
                            <path d="M5 27C3.34315 27 2 25.6569 2 24C2 22.3431 3.34315 21 5 21H9C10.6569 21 12 22.3431 12 24C12 25.6569 10.6569 27 9 27H5Z"/>
                            <path d="M36 24C36 25.6569 37.3431 27 39 27H43C44.6569 27 46 25.6569 46 24C46 22.3431 44.6569 21 43 21H39C37.3431 21 36 22.3431 36 24Z"/>
                            <path d="M9.85765 14.1004C8.68608 12.9289 8.68608 11.0294 9.85765 9.85781C11.0292 8.68623 12.9287 8.68623 14.1003 9.85781L15.5145 11.272C16.6861 12.4436 16.6861 14.3431 15.5145 15.5147C14.3429 16.6862 12.4434 16.6862 11.2719 15.5147L9.85765 14.1004Z"/>
                            <path d="M32.4851 32.4852C31.3135 33.6568 31.3135 35.5563 32.4851 36.7279L33.8993 38.1421C35.0709 39.3137 36.9704 39.3137 38.1419 38.1421C39.3135 36.9705 39.3135 35.071 38.1419 33.8995L36.7277 32.4852C35.5562 31.3137 33.6567 31.3137 32.4851 32.4852Z"/>
                            <path d="M14.1003 38.1421C12.9288 39.3137 11.0293 39.3137 9.85768 38.1421C8.68611 36.9705 8.68611 35.071 9.85768 33.8995L11.2719 32.4853C12.4435 31.3137 14.343 31.3137 15.5145 32.4853C16.6861 33.6568 16.6861 35.5563 15.5145 36.7279L14.1003 38.1421Z"/>
                            <path d="M32.4851 15.5147C33.6567 16.6862 35.5562 16.6862 36.7278 15.5147L38.142 14.1005C39.3136 12.9289 39.3136 11.0294 38.142 9.85781C36.9704 8.68624 35.0709 8.68624 33.8993 9.85781L32.4851 11.272C31.3136 12.4436 31.3136 14.3431 32.4851 15.5147Z"/>
                        </svg>
                        <div class="slider" class:toggled={ switchToggled }>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </nav>
    <div class="desktop-switch-container">
        <div class="theme-switch switch-wrapper desktop"
            style="margin-right: var(--size-6)"
            role="checkbox"
            aria-checked="false"
            aria-label="Theme switcher"
            tabindex="0"
            on:click={ toggleSwitch }
            on:keydown={ toggleSwitchKeyboard }
        >
            <svg class="switch-icon desktop light" class:toggled={ !switchToggled } width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path fill-rule="evenodd" clip-rule="evenodd" d="M20.2213 6.72105C21.2334 7.64302 21.7805 9.09552 21.4659 10.5936C20.5615 14.8997 21.7823 19.549 25.1166 22.8834C28.4509 26.2177 33.1003 27.4385 37.4064 26.5341C38.9045 26.2195 40.357 26.7666 41.2789 27.7787C42.2447 28.8389 42.6981 30.5273 41.9029 32.1423C40.9837 34.0093 39.7486 35.7567 38.2019 37.3034C30.6065 44.8988 18.2919 44.8988 10.6965 37.3034C3.10115 29.7081 3.10115 17.3935 10.6965 9.79809C12.2432 8.25139 13.9907 7.01629 15.8576 6.09709C17.4727 5.30192 19.1611 5.75526 20.2213 6.72105ZM15.1825 13.8034C15.1007 13.8812 15.0196 13.9604 14.9392 14.0407C9.68694 19.293 9.68694 27.8086 14.9392 33.0608C20.1914 38.313 28.707 38.313 33.9593 33.0608C34.0396 32.9804 34.1187 32.8993 34.1966 32.8175C29.3668 32.711 24.5635 30.8156 20.874 27.126C17.1844 23.4364 15.289 18.6332 15.1825 13.8034Z"/>
                <path d="M30 11C30 9.34315 31.3431 8 33 8C34.6569 8 36 9.34315 36 11V12H37C38.6569 12 40 13.3431 40 15C40 16.6569 38.6569 18 37 18H36V19C36 20.6569 34.6569 22 33 22C31.3431 22 30 20.6569 30 19V18H29C27.3431 18 26 16.6569 26 15C26 13.3431 27.3431 12 29 12H30V11Z"/>
            </svg>
            <svg class="switch-icon desktop dark" class:toggled={ switchToggled } width="48" height="48" viewBox="0 0 48 48" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                <path d="M21 5C21 3.34315 22.3431 2 24 2C25.6569 2 27 3.34315 27 5V9C27 10.6569 25.6569 12 24 12C22.3431 12 21 10.6569 21 9V5Z"/>
                <path fill-rule="evenodd" clip-rule="evenodd" d="M24 34C29.5228 34 34 29.5228 34 24C34 18.4772 29.5228 14 24 14C18.4772 14 14 18.4772 14 24C14 29.5228 18.4772 34 24 34ZM24 28C26.2091 28 28 26.2091 28 24C28 21.7909 26.2091 20 24 20C21.7909 20 20 21.7909 20 24C20 26.2091 21.7909 28 24 28Z"/>
                <path d="M24 36C22.3431 36 21 37.3431 21 39V43C21 44.6569 22.3431 46 24 46C25.6569 46 27 44.6569 27 43V39C27 37.3431 25.6569 36 24 36Z"/>
                <path d="M5 27C3.34315 27 2 25.6569 2 24C2 22.3431 3.34315 21 5 21H9C10.6569 21 12 22.3431 12 24C12 25.6569 10.6569 27 9 27H5Z"/>
                <path d="M36 24C36 25.6569 37.3431 27 39 27H43C44.6569 27 46 25.6569 46 24C46 22.3431 44.6569 21 43 21H39C37.3431 21 36 22.3431 36 24Z"/>
                <path d="M9.85765 14.1004C8.68608 12.9289 8.68608 11.0294 9.85765 9.85781C11.0292 8.68623 12.9287 8.68623 14.1003 9.85781L15.5145 11.272C16.6861 12.4436 16.6861 14.3431 15.5145 15.5147C14.3429 16.6862 12.4434 16.6862 11.2719 15.5147L9.85765 14.1004Z"/>
                <path d="M32.4851 32.4852C31.3135 33.6568 31.3135 35.5563 32.4851 36.7279L33.8993 38.1421C35.0709 39.3137 36.9704 39.3137 38.1419 38.1421C39.3135 36.9705 39.3135 35.071 38.1419 33.8995L36.7277 32.4852C35.5562 31.3137 33.6567 31.3137 32.4851 32.4852Z"/>
                <path d="M14.1003 38.1421C12.9288 39.3137 11.0293 39.3137 9.85768 38.1421C8.68611 36.9705 8.68611 35.071 9.85768 33.8995L11.2719 32.4853C12.4435 31.3137 14.343 31.3137 15.5145 32.4853C16.6861 33.6568 16.6861 35.5563 15.5145 36.7279L14.1003 38.1421Z"/>
                <path d="M32.4851 15.5147C33.6567 16.6862 35.5562 16.6862 36.7278 15.5147L38.142 14.1005C39.3136 12.9289 39.3136 11.0294 38.142 9.85781C36.9704 8.68624 35.0709 8.68624 33.8993 9.85781L32.4851 11.272C31.3136 12.4436 31.3136 14.3431 32.4851 15.5147Z"/>
            </svg>
            <div class="slider desktop" class:toggled={ switchToggled }>
            </div>
        </div>
    </div>
</div>

{#if isMenuOpen}
    <div class="backdrop"
        role="presentation"
        on:click={ toggleMenu }
        transition:fade={{ duration: 500, easing: quintOut}}
    ></div>
{/if}



<style>
    .modal-container {
        display: flex;
        justify-content: center;
        align-items: center;
        position: fixed;
        top: 0;
        height: 100vh;
        z-index: 101;
    }

    .menu-modal {
        position: fixed;
        overflow: hidden;
        width: calc(var(--size-18) * 2.5);
        height: 0;
        background-color: var(--neutral-900);
        border-radius: calc(var(--size-11));
    }

    .modal-wrapper {
        width: 100%;
        height: 100%;
        padding: var(--size-9);
    }

    .modal-text {
        padding: 0 0 var(--size-7) var(--size-7);
        color: var(--neutral-200);
    }

    .modal-title {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    #MODAL-BACKDROP {
        z-index: 100;
    }

    #MODAL-CLOSE {
        color: var(--neutral-100);
    }

    .visible {
        height: calc(var(--size-18) * 1.5);
    }

    .nav-container {
        position: relative;
        display: flex;
        justify-content: center;
        position: fixed;
        z-index: 99;
        width: 100%;
        height: 0;
        margin-top: 2vh;
    }

    nav {
        overflow: hidden;
        width: calc(80vw - var(--size-9));
        height: calc(var(--size-14) - var(--size-1));
        background-color: var(--neutral-300);
        border-radius: calc(var(--size-11) - var(--size-0));
    }

    .menu-container {
        height: 100%;
    }

    .dropdown {
        height: calc(var(--size-14) - var(--size-1) + (var(--size-18) * 1.5));
    }

    .backdrop {
        display: block;
        position: fixed;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 98;
        opacity: 100%;
        background-color: var(--neutral-100-opacity);
        backdrop-filter: blur(var(--size-6));
        cursor: pointer;
    }

    a {
        padding: var(--size-5);
        font-size: var(--size-9);
        border-radius: var(--size-11);
    }

    button {
        padding: var(--size-5);
        font-size: var(--size-9);
        border-radius: var(--size-11);
    }

    .interactive {
        text-wrap: nowrap;
        color: var(--neutral-700);
    }

    .interactive:hover {
        background-color: var(--neutral-200);
    }

    .interactive:active {
        background-color: var(--neutral-100);
    }

    .interactive.reversed {
        text-wrap: nowrap;
        color: var(--neutral-100);
    }

    .interactive.reversed:hover {
        background-color: var(--neutral-800);
    }

    .interactive.reversed:active {
        background-color: var(--neutral-700);
    }

    .active {
        color: var(--neutral-900);
        background-color: var(--neutral-200);
    }

    .menu-top {
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: calc(var(--size-14) - var(--size-1));
        padding: var(--size-6);
    }

    .logo {
        font-family: Sora;
        font-size: var(--size-9);
        font-weight: 600;
        line-height: 100%;
        color: var(--neutral-900);
    }

    .symbol {
        width: var(--size-10);
        height: var(--size-10);
        margin: 0 var(--size-0) 0 var(--size-0);
    }

    .menu-bot {
        position: relative;
        flex-direction: column;
        justify-content: space-between;
        height: calc(var(--size-18) * 1.5);
        padding: var(--size-6);
    }

    .menu-bot a {
        justify-content: start;
        margin-bottom: var(--size-5);
        padding: var(--size-5);
        font-size: var(--size-11);
        border-radius: var(--size-5);
    }

    .menu-splitter {
        display: flex;
    }

    .first-area {
        width: 30%;
        margin-right: var(--size-10)
    }

    .divider {
        width: var(--size-2);
        height: calc(var(--size-18) * 1.325);
        background-color: var(--neutral-200);
        border-radius: var(--size-8);
    }

    .second-area {
        width: 50%;
        margin-left: var(--size-8)
    }

    .text {
        max-width: calc(var(--size-18) * 2.65);
        padding: 0 var(--size-7) var(--size-7) var(--size-7);
        color: var(--neutral-900);
    }

    .c2a {
        display: flex;
        align-items: center;
    }

    .c2a a {
        margin-bottom: 0;
        font-size: calc(var(--size-10) - var(--size-1));
        color: var(--neutral-900);
        border-radius: var(--size-11);
    }

    .interim {
        margin: 0 var(--size-6) 0 var(--size-6);
        font-size: var(--size-9);
        line-height: 140%;
        color: var(--neutral-600);
    }

    .menu-icon {
        width: calc(var(--size-12) - var(--size-1));
        height: calc(var(--size-12) - var(--size-1));
    }

    .linkedin-icon {
        width: var(--size-11);
        height: var(--size-11);
    }

    button#MENU-BUTTON {
        width: var(--size-13);
        height: var(--size-13);
        padding: 0;
        color: var(--neutral-900);
        border-radius: var(--size-10);
    }

    .rect {
        transform-origin: center;
        transition: 
            y 0.25s cubic-bezier(0.22, 1, 0.36, 1),
            rotate 0.25s cubic-bezier(0.22, 1, 0.36, 1) 0.25s;
    }

    #MENU-BUTTON[aria-expanded="false"] .rect {
        transition: 
            y 0.25s cubic-bezier(0.22, 1, 0.36, 1) 0.25s,
            rotate 0.25s cubic-bezier(0.22, 1, 0.36, 1);
    }

    #MENU-BUTTON[aria-expanded="false"] .top {
        y: 12;
        rotate: -45deg;
    }

    #MENU-BUTTON[aria-expanded="false"] .bot {
        y: 28;
        rotate: 45deg;
    }

    .switch-container {
        display: flex;
        justify-content: center;
        width: 100%;
    }

    .switch-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        z-index: 1;
        width: var(--size-16);
        height: var(--size-13);
        color: var(--neutral-900);
        background-color: var(--neutral-100);
        border-radius: var(--size-10);
        cursor: pointer;
    }

    .desktop-switch-container {
        position: absolute;
        display: flex;
        align-items: center;
        height: calc(var(--size-14) - var(--size-1));
        margin-right: var(--size-1);
        right: 0;
    }

    .switch-wrapper.desktop {
        width: var(--size-15);
        height: var(--size-12);
        outline: solid var(--size-1) var(--neutral-300);
    }

    .slider {
        position: absolute;
        left: var(--size-3);
        width: var(--size-12);
        height: var(--size-12);
        background-color: var(--neutral-900);
        border-radius: var(--size-10);
    }

    .slider.desktop {
        left: var(--size-2);
        width: var(--size-11);
        height: var(--size-11);
    }

    .slider.toggled {
        transform: translateX(calc(var(--size-13) - var(--size-2) + var(--size-2) - var(--size-1)));
    }

    .slider.desktop.toggled {
        transform: translateX(calc(var(--size-12) - var(--size-2) + var(--size-1) - var(--size-0)));
    }

    .switch-icon {
        width: var(--size-11);
        height: var(--size-11);
        margin: var(--size-6);
        transform-origin: center;
        transition: 
            1s cubic-bezier(0.22, 1, 0.36, 1),
            width 0.5s cubic-bezier(0.22, 1, 0.36, 1),
            height 0.5s cubic-bezier(0.22, 1, 0.36, 1);
    }

    .switch-icon.desktop {
        width: var(--size-10);
        height: var(--size-10);
        margin: var(--size-5);
    }

    .switch-icon.light.toggled {
        width: 0;
        height: 0;
        transform: rotate(-360deg);
    }

    .switch-icon.dark.toggled {
        width: 0;
        height: 0;
        transform: rotate(360deg);
    }

    .menu-desktop {
        display: flex;
        align-items: center;
    }

    .menu-mobile {
        display: none;
    }

    .menu-bot {
        display: none;
    }

    @media only screen and (max-width: 989px) {
        nav {
            width: calc(90vw - var(--size-11));
        }

        .desktop-switch-container {
            display: none;
        }

        .menu-desktop {
            display: none;
        }

        .menu-mobile {
            display: block;
        }

        .menu-bot {
            display: flex;
        }

        .switch-container {
            position: absolute;
            bottom: 0;
            left: 0;
            width: auto;
            margin: var(--size-8);
        }
    }

    @media only screen and (max-width: 479px) {
        .dropdown {
            height: calc(var(--size-14) - var(--size-1) + (var(--size-18) * 3));
        }

        .menu-bot {
            position: relative;
            flex-direction: column;
            justify-content: space-between;
            height: calc(var(--size-18) * 3);
            padding: var(--size-6);
        }

        .menu-splitter {
            flex-direction: column;
        }

        .first-area {
            width: 100%;
            margin-right: 0;
        }

        .divider {
            display: none;
        }

        .second-area {
            width: 100%;
            margin-top: var(--size-11);
            margin-left: 0;
        }

        .switch-container {
            position: relative;
            width: 100%;
            margin: 0;
        }
    }
</style>