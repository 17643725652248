<script context="module">
    import { currentTheme } from '$routes/stores';
    import { get } from 'svelte/store';

    let paletteUnsub;
    let palette = {
        hlTangerine: '#f7a863',
        hlLime: '#92ed4c',
        hlBlue: '#6bceff',
        hlIndigo: '#c694ff',
        hlRed: '#fd8391'
    };

    function setLight() {
        palette = {
            ...palette,
            neutral1: '#FCFEFF',
            neutral2: '#EAECED',
            neutral3: '#D4D7D9',
            neutral4: '#ADB0B2',
            neutral5: '#8D9094',
            neutral6: '#686B70',
            neutral7: '#4E5057',
            neutral8: '#3A3B42',
            neutral9: '#232328'
        }
    };

    function setDark() {
        palette = {
            ...palette,
            neutral1: '#232328',
            neutral2: '#3A3B42',
            neutral3: '#4E5057',
            neutral4: '#686B70',
            neutral5: '#8D9094',
            neutral6: '#ADB0B2',
            neutral7: '#D4D7D9',
            neutral8: '#EAECED',
            neutral9: '#FCFEFF'
        }
    };

    const pureColors = [
        0.9734452903978066,
        0.822785754392438,
        0.6583748172725346,
        0.4178850708380236,
        0.2663556047920505,
        0.13843161502267545,
        0.07618538147321911,
        0.04231141061442144,
        0.01680737574872402
    ];
    
    const inversedColors = [
        '#232328',
        '#3A3B42',
        '#4E5057',
        '#686B70',
        '#8D9094',
        '#ADB0B2',
        '#D4D7D9',
        '#EAECED',
        '#FCFEFF'
    ];

    function colorUpdater(material, color) {
        const check = pureColors.indexOf(color);
        if (check > -1) material.set(inversedColors[check])
    };

    function paletteInit(scene) {
        if (get(currentTheme) === 'light') setLight();
        if (get(currentTheme) === 'dark') setDark();

        paletteUnsub = currentTheme.subscribe((value) => {
            if (value === 'light') setLight();
            if (value === 'dark') setDark();
            updateObjects(scene);
        });
    };

    function updateObjects(scene) {
        scene.traverse((object) => {
            if (object.material) {
                colorUpdater(object.material.color, object.material.color.r)
            }
        });
    };

    export { palette, paletteInit, paletteUnsub }
</script>