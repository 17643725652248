<script>
    import { onMount } from 'svelte';
    import { quintOut } from 'svelte/easing';
    import { fade } from 'svelte/transition';

    import * as T from 'three';
    import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
    import WebGL from 'three/addons/capabilities/WebGL.js';

    import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
    import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
    import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
    import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

    import { heroActive, gltfList, propsList } from '$routes/stores';
    import { loadGeometries } from '$scenes/GeoLoader.svelte';
    import { palette, paletteInit } from '$scenes/PaletteHandler.svelte';

    let fallback = false;
    let loader = true;
    let wrapperTarget, canvasTarget, footerTarget;
    let renderer, composer, controls, camera, bloomPass;
    let canvas = {
        width: 1536,
        height: 576
    };

    const clock = new T.Clock();
    const scene = new T.Scene();
    function threeInit() {
        function sceneInit() {
            camera = new T.PerspectiveCamera(55, canvas.width / canvas.height, 1, 1000);
            camera.position.z = 10;
            camera.position.y = 1;
            camera.layers.enable(1);
            scene.add(camera);

            const ambientLight = new T.AmbientLight('#c694ff', 0.5);
            scene.add(ambientLight);

            const topLight = new T.SpotLight('#c694ff', 1);
            topLight.position.y = 5.5;
            topLight.castShadow = true;
            topLight.power = 700;
            topLight.angle = Math.PI;
            scene.add(topLight);

            const botLight = new T.SpotLight(0xffffff, 1);
            botLight.position.y = -8;
            botLight.castShadow = true;
            botLight.power = 750;
            botLight.angle = Math.PI;
            scene.add(botLight);
        }

        function renderInit() {
            wrapperTarget = document.getElementById("CALLER-WRAPPER");
            canvasTarget = document.getElementById("CALLER");
            renderer = new T.WebGLRenderer({ canvas: canvasTarget, alpha: true });
            renderer.setClearColor(0xffffff, 0);
            renderer.shadowMap.enabled = true;

            const renderScene = new RenderPass(scene, camera);
            composer = new EffectComposer(renderer);
            composer.addPass(renderScene);

            const vector = new T.Vector2(canvas.width, canvas.height);
            bloomPass = new UnrealBloomPass(vector, 0.15, 1, 0);
            composer.addPass(bloomPass);

            const outputPass = new OutputPass();
            composer.addPass(outputPass);
        }

        function controlsInit() {
            controls = new OrbitControls(camera, canvasTarget);
            controls.autoRotate = true;
            controls.autoRotateSpeed = 1
            controls.enabled = false;
        }

        sceneInit();
        renderInit();
        controlsInit();
        checkViewport();
        window.addEventListener('resize', checkViewport);
    };

    function checkViewport() {
        const newSize = wrapperTarget.getBoundingClientRect();
        canvas.width = newSize.width;
        canvas.height = newSize.height;
        camera.aspect = canvas.width / canvas.height;
        camera.updateProjectionMatrix();

        const vector = new T.Vector2(canvas.width, canvas.height);
        bloomPass.setSize(vector);
        renderer.setSize(canvas.width, canvas.height);
        renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
        composer.setSize(canvas.width, canvas.height);
        composer.setPixelRatio(Math.min(window.devicePixelRatio, 2));
    };

    let rapierDebugger;
    class RapierDebugRenderer {
        enabled = false;

        constructor(scene, world) {
            this.world = world
            this.mesh = new T.LineSegments(new T.BufferGeometry(),
            new T.LineBasicMaterial({ color: 0xffffff, vertexColors: true }))
            this.mesh.frustumCulled = false
            scene.add(this.mesh)
        }

        update() {
            if (this.enabled) {
                const { vertices, colors } = this.world.debugRender()
                this.mesh.geometry.setAttribute('position', new T.BufferAttribute(vertices, 3))
                this.mesh.geometry.setAttribute('color', new T.BufferAttribute(colors, 4))
                this.mesh.visible = true
            } else {
                this.mesh.visible = false
            }
        }
    };

    let R, world;
    async function rapierInit() {
        R = await import('https://cdn.skypack.dev/@dimforge/rapier3d-compat');
        await R.init();
        const gravity = { x: 0.0, y: 0, z: 0.0 }
        world = new R.World(gravity);
        rapierDebugger = new RapierDebugRenderer(scene, world);
    };

    let spawnGeoList = []; 
    let sceneGeoList = [];
    let IDList = [];
    let takenIDs = [];
    let objectList = [];
    async function geoInit(spawnIndex = 2.5, sceneIndex = 6) {
        spawnGeoList = await loadGeometries(gltfList)
        for (let i = 0; i < spawnGeoList.length; i++) {
            spawnGeoList[i].scale(spawnIndex, spawnIndex, spawnIndex);
            IDList.push(i)
        };
        sceneGeoList = await loadGeometries(propsList)
        for (let i = 0; i < sceneGeoList.length; i++) {
            sceneGeoList[i].scale(sceneIndex, sceneIndex, sceneIndex);
        }
    };

    class object {
        constructor(id) {
            this.id = id,
            this.geometry = spawnGeoList[this.id],
            this.material,
            this.mesh,
            this.rigid,
            this.body,
            this.collider,
            this.light = null
        }

        setMaterial() {
            if (this.id === 0) {
                this.material = new T.MeshStandardMaterial({
                    color: palette.hlIndigo,
                    roughness : 0.75,
                    metalness : 0.25
                })
                this.addLight(palette.hlIndigo)
            } else if (this.id === 3) {
                this.material = new T.MeshStandardMaterial({
                    color: palette.hlBlue,
                    roughness : 0.75,
                    metalness : 0.25
                })
                this.addLight(palette.hlBlue)
            } else if (this.id === 5) {
                this.material = new T.MeshStandardMaterial({
                    color: palette.hlLime,
                    roughness : 0.75,
                    metalness : 0.25
                })
                this.addLight(palette.hlLime)
            } else if (this.id === 11) {
                this.material = new T.MeshStandardMaterial({
                    color: palette.hlTangerine,
                    roughness : 0.75,
                    metalness : 0.25
                })
                this.addLight(palette.hlTangerine)
            } else if (this.id === 12) {
                this.material = new T.MeshStandardMaterial({
                    color: palette.hlRed,
                    roughness : 0.75,
                    metalness : 0.25
                })
                this.addLight(palette.hlRed)
            } else {
                this.material = new T.MeshStandardMaterial({
                    color: palette.neutral1,
                    roughness : 1,
                    metalness : 0
                })

                if (Math.random() < 0.5) {
                    this.material.roughness = 0.75;
                    this.material.metalness = 1;
                };
            }
        }

        addLight(color) {
            this.light = new T.PointLight(color, 1, 5);
            this.light.power = 100
        }

        generatePositionNum(max = 2) {
            let base = Math.random() * max + 1;
            if (Math.random() < 0.5) base *= -1;
            return base;
        }

        setPosition() {
            this.mesh.position.x = this.generatePositionNum();
            this.mesh.position.y = this.generatePositionNum(1);
            this.mesh.position.z = this.generatePositionNum();

            let position = {
                x: this.mesh.position.x,
                y: this.mesh.position.y,
                z: this.mesh.position.z
            };
            this.rigid.setTranslation(position);
        }

        generateRotationNum(max = 0.5) {
            let base = Math.PI * Math.random() * max;
            if (Math.random() < 0.5) base *= -1;
            return base;
        }

        setRotation() {
            this.mesh.rotation.x = this.generateRotationNum();
            this.mesh.rotation.y = this.generateRotationNum();
            this.mesh.rotation.z = this.generateRotationNum();

            let quaternion = new T.Quaternion().setFromEuler(
                new T.Euler(
                    this.mesh.rotation.x,
                    this.mesh.rotation.y,
                    this.mesh.rotation.z
                )
            );

            this.rigid.setRotation(quaternion);
        }

        create() {
            this.setMaterial();

            this.mesh = new T.Mesh(this.geometry, this.material);
            this.mesh.castShadow = true;

            this.rigid = world.createRigidBody(R.RigidBodyDesc.dynamic());
            this.rigid.setAngularDamping(1);
            this.rigid.setLinearDamping(0.5);
            this.body = new Float32Array(this.geometry.attributes.position.array);
            this.collider = R.ColliderDesc.convexHull(this.body);
            this.collider.setDensity(50);
            this.collider.setRestitution(1);

            this.setPosition();
            this.setRotation();
            scene.add(this.mesh);
            world.createCollider(this.collider, this.rigid)
        }

        update() {
            let { x, y, z } = this.rigid.translation();
            this.mesh.position.set(x, y, z);
            this.mesh.quaternion.copy(this.rigid.rotation());
            if (this.light != null) {
                this.mesh.add(this.light)
            };

            let linVelocity = this.rigid.linvel();
            let linSpeed = Math.sqrt(linVelocity.x ** 2 + linVelocity.y ** 2 + linVelocity.z ** 2);
            let maxLinSpeed = 7;
            if (linSpeed > maxLinSpeed) {
                let scaleFactor = maxLinSpeed / linSpeed;
                let newVelocity = {
                    x: linVelocity.x * scaleFactor,
                    y: linVelocity.y * scaleFactor,
                    z: linVelocity.z * scaleFactor
                };
                this.rigid.setLinvel(newVelocity, true)
            };

            let angVelocity = this.rigid.angvel();
            let angSpeed = Math.sqrt(angVelocity.x ** 2 + angVelocity.y ** 2 + angVelocity.z ** 2);
            let maxAngSpeed = 5;
            if (angSpeed > maxAngSpeed) {
                let scaleFactor = maxAngSpeed / angSpeed;
                let newVelocity = {
                    x: angVelocity.x * scaleFactor,
                    y: angVelocity.y * scaleFactor,
                    z: angVelocity.z * scaleFactor
                };
                this.rigid.setAngvel(newVelocity, true)
            }
        }
    };

    function assignID() {
        let candidates = [];
        for (let i = 0; i < IDList.length; i++) {
            if (!takenIDs.includes(IDList[i])) {
                candidates.push(IDList[i])
            }
        };
        let result = candidates[Math.floor(Math.random() * candidates.length)];
        takenIDs.push(result)

        return result;
    };

    function spawnObject() {
        const spawn = new object(assignID())
        objectList.push(spawn);
        spawn.create()
    };

    async function spawnerInit(limit) {
        for (let i = 0; i < limit; i++) {
            spawnObject()
        }
    };

    function spawnerUpdate() {
        objectList.forEach((el) => {
            el.update();
        });
    };

    class platform {
        constructor() {
            this.geometry = sceneGeoList[0],
            this.material = new T.MeshStandardMaterial({ 
                color: palette.neutral9,
                roughness: 1,
                metalness: 0
            }),
            this.mesh,
            this.body,
            this.index,
            this.collider
        }

        initialize() {
            this.mesh = new T.Mesh(this.geometry, this.material);
            this.body = new Float32Array(this.geometry.attributes.position.array);
            this.index = new Uint32Array(this.geometry.index.array);
            this.collider = R.ColliderDesc.trimesh(this.body, this.index);
            this.collider.setDensity(10);
            this.collider.setRestitution(10);
            this.collider.setRestitutionCombineRule(R.CoefficientCombineRule.Min)
        }

        create() {
            scene.add(this.mesh)
            world.createCollider(this.collider);
        }
    };

    function sceneBackground() {
        const platformTop = new platform();
        platformTop.initialize();
        platformTop.mesh.position.y = 5;
        platformTop.collider.setTranslation(0, 5, 0)
        platformTop.create()

        const wallsGeometry = sceneGeoList[1];
        const wallsBody = new Float32Array(wallsGeometry.attributes.position.array);
        const wallsIndex = new Uint32Array(wallsGeometry.index.array);
        const wallsMaterial = new T.MeshStandardMaterial({ 
            color: palette.neutral9,
            roughness: 1,
            metalness: 0.25
        });
        const wallsMesh = new T.Mesh(wallsGeometry, wallsMaterial);
        const wallsCollider = R.ColliderDesc.trimesh(wallsBody, wallsIndex);
        scene.add(wallsMesh);
        world.createCollider(wallsCollider);
    };

    let ballRigid;
    function ballInit() {
        const ballBody = R.RigidBodyDesc.kinematicPositionBased();
        const ballCollider = R.ColliderDesc.ball(3);
        ballRigid = world.createRigidBody(ballBody);
        ballRigid.setTranslation(0, 20, 0)

        world.createCollider(ballCollider, ballRigid);
    }

    const raycaster = new T.Raycaster();
    const pointer = new T.Vector2();
    function checkMousePos(event) {
        if (event.isPrimary === false) return;
        const rect = wrapperTarget.getBoundingClientRect();
        pointer.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        pointer.y = - ((event.clientY - rect.top) / rect.height) * 2 + 1;

        raycaster.setFromCamera(pointer, camera);
        const planeNormal = new T.Vector3();
        camera.getWorldDirection(planeNormal)

        const plane = new T.Plane().setFromNormalAndCoplanarPoint(planeNormal, new T.Vector3(0, 0, 0));
        const intersection = new T.Vector3();
        raycaster.ray.intersectPlane(plane, intersection);

        ballRigid.setTranslation(intersection);
    }

    let magnet, magnetRigid;
    const magnetParams = {
        geometry: new T.SphereGeometry(1.5, 32, 16),
        material: null,
        origin: new T.Vector3(0, 1, 0)
    };

    function magnetInit() {
        magnetParams.material = new T.MeshStandardMaterial({
            color: palette.hlIndigo,
            roughness: 0.75,
            metalness: 1,
            transparent: true,
            opacity: 0.5
        })
        magnet = new T.Mesh(magnetParams.geometry, magnetParams.material);
        const magnetCollider = R.ColliderDesc.ball(1.5);
        const magnetBody = R.RigidBodyDesc.kinematicPositionBased().setTranslation(0, 1, 0);
        magnetRigid = world.createRigidBody(magnetBody);

        scene.add(magnet);
        world.createCollider(magnetCollider, magnetRigid);
        footerTarget = document.getElementById('FOOTER');
        footerTarget.addEventListener('pointermove', checkMousePos);
        ballInit(); 
    };

    function magnetUpdate() {
        const t = clock.getElapsedTime();
        const posCalculation = 0 + Math.sin( t * 1.5 ) * .5;
        const vector = new T.Vector3(0, posCalculation, 0)
        magnet.position.y = posCalculation;
        magnetRigid.setTranslation(vector, true);
        magnetParams.origin.set(0, posCalculation, 0);
        magnetActivate();
    }

    function magnetActivate() {
        world.forEachRigidBody((el) => {
            el.resetForces(true);
            el.resetTorques(true);
            let { x, y, z } = el.translation();
            let pos = new T.Vector3(x, y, z);
            let dir = pos.clone().sub(magnetParams.origin).normalize();
            el.addForce(dir.multiplyScalar(-250), true);
        })
    };

    async function buildSim() {
        sceneBackground();
        await spawnerInit(13);
        magnetInit();
    };

    let mounted = false;
    $: { $heroActive;
        if (mounted === true) loop()
    }

    let lastFrame = 0;
    const frameInterval = 1000 / 300;
    function loop() {
        const currentTime = performance.now();
        const timePassed = currentTime - lastFrame;
        if (timePassed > frameInterval) {
            composer.render()
            world.step();
            controls.update();
            rapierDebugger.update();
            spawnerUpdate();
            magnetUpdate();
            lastFrame = currentTime;
        };
        requestAnimationFrame(loop);
    };

    function checkSupport() {
        if (!WebGL.isWebGL2Available()) {
            fallback = true
        }
    }

    onMount(async () => {
        checkSupport();
        threeInit();
        paletteInit(scene);
        await rapierInit();
        await geoInit(6);
        await buildSim()
        mounted = true;
        loader = false;
        loop();
    })
</script>

<div id="CALLER-WRAPPER">
    <canvas id="CALLER" />
    {#if loader}
        <div id="LOADER"
            transition:fade={{ duration: 1000, easing: quintOut}}
        ></div>
    {/if}
    <!-- <div class="text-wrapper">
        <p>Find someone who can bring everything together</p>
    </div> -->
    {#if fallback}
        <div class="blocker">
            <svg class="menu-icon" width="100%" height="100%" viewBox="0 0 48 48" fill="currentColor">
                <rect class="rect top" width="32" height="8" x="8" y="20" rx="2" transform="rotate(45)" fill="currentColor"></rect>
                <rect class="rect bot" width="32" height="8" x="8" y="20" rx="2" transform="rotate(-45)" fill="currentColor"></rect>
            </svg>
            <span>WebGL not supported</span>
        </div>
    {/if}
</div>

<style>
    #CALLER-WRAPPER {
        position: relative;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 100%;
    }

    #CALLER {
        width: 100%;
        height: 100%;
    }

    #LOADER {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: var(--neutral-900);
        animation: loading 2s linear 0s infinite forwards;
    }

    .text-wrapper {
        position: absolute;
        max-width: 80%;
        top: var(--size-16);
        padding: var(--size-4) var(--size-6);
        background-color: var(--neutral-900-opacity);
        border-radius: var(--size-11);
        backdrop-filter: blur(var(--size-3));
    }

    p {
        width: 100%;
        text-align: center;
        font-size: calc(var(--size-8) - var(--size-0));
        font-weight: 500;
        color: var(--neutral-100);
        opacity: 70%;
    }

    @keyframes loading {
        0% {
            background-color: var(--neutral-900);
        } 50% {
            background-color: var(--neutral-800);
        } 100% {
            background-color: var(--neutral-900);
        }
    }

    .blocker {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: var(--size-4);
        width: 100%;
        height: 100%;
        background-color: var(--neutral-200);
    }

    .menu-icon {
        width: calc(var(--size-12) - var(--size-1));
        height: calc(var(--size-12) - var(--size-1));
    }

    .rect {
        transform-origin: center;
        transition: 
            y 0.25s cubic-bezier(0.22, 1, 0.36, 1),
            rotate 0.25s cubic-bezier(0.22, 1, 0.36, 1) 0.25s;
    }

    @media only screen and (max-width: 989px) {
        .text-wrapper {
            top: var(--size-17);
        }
    }

    @media only screen and (max-width: 479px) {
        .text-wrapper {
            top: calc(var(--size-18) - var(--size-10));
        }
    }
</style>