<script type="text/javascript">
    import Caller from '$scenes/Caller.svelte';

    const year = new Date().getFullYear();
</script>

<footer id="FOOTER" class="footer-container">
    <div class="footer-wrapper">
        <div class="c2a-wrapper">
            <div class="text">
                <h2 class="special-heading">Get in touch</h2>
                <p class="body1" style="margin-top: var(--size-5); color: var(--neutral-300)">Drop an email or a message on LinkedIn to get things started</p>
            </div>
            <div class="c2a">
                <a href="mailto:contact@uxdrop.club?subject=Hey,%20Alex"
                    class="interactive"
                    style="height: var(--size-13)"
                    aria-label="Send an email to Alex"
                ><span class="typography">send an email</span></a>
                <span class="interim">or</span>
                <a href="https://www.linkedin.com/in/alex-i-brad/"
                    target="_blank"
                    class="interactive"
                    aria-label="Open Alex's Linkedin profile in a new tab"
                >
                    <svg class="linkedin-icon" width="100%" height="100%" viewBox="0 0 48 48" fill="currentColor">
                        <path d="M14.0572 9.42862C14.0572 12 12 14.0572 9.42862 14.0572C6.85719 14.0572 4.80005 12 4.80005 9.42862C4.80005 6.85719 6.85719 4.80005 9.42862 4.80005C12 4.80005 14.0572 6.85719 14.0572 9.42862Z"/>
                        <path d="M43.2 28.9715V43.2H34.8V31.2C34.8 23.3143 26.4 24 26.4 31.2V43.2H17.8286V17.8286H26.2286V22.4572C30 15.7715 43.2 15.2572 43.2 28.9715Z"/>
                        <path d="M13.5429 17.8286H5.14291V43.2H13.5429V17.8286Z"/>
                    </svg>
                </a>
            </div>
        </div>
        <div class="extras">
            <div><span>© Alex Brad {year}</span></div>
            <a href="/terms"
                class="interactive"
                aria-label="Go to Terms & Conditions"
            >Terms & Conditions</a>
        </div>
    </div>
    <div class="scene-wrapper">
        <Caller />
    </div>
</footer>

<style>
    .footer-container {
        position: fixed;
        z-index: -1;
        bottom: 0;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 90vh;
    }

    .footer-wrapper {
        position: relative;
        z-index: 1;
        display: flex;
        flex-direction: column;
        justify-content: end;
        width: 80%;
        height: 100%;
        margin: 0 0 var(--size-11) 0;
    }

    .c2a-wrapper {
        max-width: calc(var(--size-18) * 2.5);
        padding: var(--size-10) var(--size-8) var(--size-8) var(--size-8);
        background-color: var(--neutral-900-opacity);
        border-radius: var(--size-11);
        backdrop-filter: blur(var(--size-3));
    }

    a {
        padding: var(--size-5);
        font-size: var(--size-9);
        border-radius: var(--size-11);
    }

    .interactive {
        color: var(--neutral-400);
    }

    .interactive:hover {
        background-color: var(--neutral-700);
    }

    .interactive:active {
        background-color: var(--neutral-600);
    }

    .linkedin-icon {
        width: var(--size-11);
        height: var(--size-11);
    }

    .text {
        padding: 0 var(--size-7) var(--size-7) var(--size-7);
        color: var(--neutral-100);
    }

    .c2a {
        display: flex;
        align-items: center;
    }

    .c2a a {
        margin-bottom: 0;
        font-size: calc(var(--size-10) - var(--size-0));
        color: var(--neutral-100);
        border-radius: var(--size-11);
    }

    .interim {
        margin: 0 var(--size-6) 0 var(--size-6);
        font-size: var(--size-9);
        line-height: 140%;
        color: var(--neutral-400);
    }

    .extras {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        padding: var(--size-14) var(--size-10) 0 var(--size-10);
        font-family: Sora;
        font-size: var(--size-7);
        line-height: 130%;
        color: var(--neutral-500);
    }

    .extras a {
        font-family: Sora;
        font-size: var(--size-7);
        font-weight: 400;
        color: var(--neutral-500);
    }

    .scene-wrapper {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
    }

    @media only screen and (max-width: 989px) {
        .footer-wrapper {
            width: 90%;
        }
    }
</style>