<script>
	import './styles.css';
	import Egg from '$components/Egg.svelte';
	import Header from '$components/Header.svelte';
	import Footer from '$components/Footer.svelte';
	
	import { loaded } from '$routes/stores';
	import { onMount } from 'svelte';
	import { afterNavigate } from '$app/navigation';
	import { fly, fade } from 'svelte/transition';
	import { quintOut } from 'svelte/easing';

	let parallaxElements;
	function updateParallax(list) {
		if ( list.length == 0 ) {
			return
		};
		list.forEach((item) => parallaxChecker(item))
	};

	function parallaxChecker(el) {
		let rect = el.getBoundingClientRect();
		if (rect.bottom < 0 || rect.top > window.innerHeight) {
			return;
		}

		let percentageInViewport = (window.innerHeight - rect.top) / (window.innerHeight + rect.height);
		let parallaxValue = percentageInViewport * 8;
		el.style.transform = `translate3d(0, ${ parallaxValue }%, 0)`;
	};

	let loaderCondition = true;
	$: { $loaded;
		setTimeout(() => {
			loaderCondition = false;
		}, 1500)
	}

	onMount(() => {
		parallaxElements = document.querySelectorAll('.parallax');
		updateParallax(parallaxElements);
		document.addEventListener('scroll', function () {
			updateParallax(parallaxElements)
		});
		loaded.set(true);
	});

	afterNavigate(() => {
		parallaxElements = document.querySelectorAll('.parallax');
		updateParallax(parallaxElements)
	});

	export let data;
</script>

<div class="app">
	<Egg />
	<div class="host">
		<Header />
		<main>
			{#key data.url}
				<div class="main-wrapper"
					in:fly={{ y: -200, duration: 500, delay: 500 }}
					out:fly={{ y: 200, duration: 500 }}
				>
					<slot />
				</div>
			{/key}
		</main>
	</div>
	<Footer />
	{#if loaderCondition}
		<div class="loader"
			transition:fade={{ duration: 500, easing: quintOut}}
		>
			<svg xmlns="http://www.w3.org/2000/svg" class="symbol" height="100%" viewBox="0 0 48 48" fill="none">
				<g clip-path="url(#clip0_1406_7744)">
					<path d="M47.7437 24.6829L39.3471 33.0732C38.9565 33.4634 38.3707 33.4634 37.9802 33.0732L24.0183 19.122L10.0564 33.0732C9.6659 33.4634 9.08009 33.4634 8.68955 33.0732L0.292906 24.6829C-0.0976354 24.2927 -0.0976354 23.7073 0.292906 23.3171L14.9382 8.68293L23.3349 0.292683C23.7254 -0.097561 24.3112 -0.097561 24.7018 0.292683L33.0984 8.68293L47.7437 23.3171C48.0854 23.6585 48.0854 24.2927 47.7437 24.6829ZM33.0984 37.9512L24.7018 29.561C24.3112 29.1707 23.7254 29.1707 23.3349 29.561L14.9382 37.9512C14.5477 38.3415 14.5477 38.9268 14.9382 39.3171L23.3349 47.7073C23.7254 48.0976 24.3112 48.0976 24.7018 47.7073L33.0984 39.3171C33.4401 38.9268 33.4401 38.2927 33.0984 37.9512Z" fill="currentColor"/>
				</g>
			</svg>
		</div>
	{/if}
</div>

<style>
	.host {
		display: flex;
		justify-content: center;
		width: 100%;
		margin-bottom: 80vh;
		background-color: var(--neutral-100);
		border-radius: 0 0 var(--size-13) var(--size-13);
	}

	main {
		display: flex;
		flex-direction: column;
		align-items: center;
		width: 100%;
		padding: calc(4vh + var(--size-14) - var(--size-1)) 0 5vw 0;
	}

	.main-wrapper {
		width: 100%;
	}

	.loader {
		position: fixed;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: 101;
		display: flex;
		justify-content: center;
		align-items: center;
		width: 100vw;
		height: 100vh;
		background-color: var(--neutral-100);
	}

	.symbol {
		width: var(--size-18);
		height: var(--size-18);
		animation: loading 2s linear 0s infinite forwards;
	}

	@keyframes loading {
		0% {
			color: var(--neutral-200);
		} 50% {
			color: var(--highlight-indigo);
		} 100% {
			color: var(--neutral-200);
		}
	}

	@media only screen and (max-width: 989px) {
		main {
			padding-bottom: 5vw;
		}
	}
</style>